import './App.css';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <p>
          <code>RedPage International</code> will be available soon.
        </p>
      </header>

    </div>
  );
}

export default App;
